import { call, takeLatest, put } from "redux-saga/effects"
import { formatDate, sagaWrapper } from "src/helpers"
import { Service } from "src/services"
import { Action, CreditProposalType, Response } from "src/types"
import { creditProposalsActions } from "./actions"
import { CREDIT_PROPOSAL_TYPES } from "./types"
import { snackbarActions } from "../snackbar/actions"

const applicationWorkflowService = new Service('application_workflows')
const creditProposalService = new Service('credit_proposals')
const productApplicationService = new Service('product_applications')
const abacusProductApplicationService = new Service('product_applications', { api: 'abacus' })
// const abacusProductTypeService = new Service('products', { api: 'abacus' })

function* getAll({ payload }: Action) {
    const response: Response = yield call(applicationWorkflowService.allPath, `${payload.applicationId}/credit_proposals`, payload.params)
    yield put(creditProposalsActions.setAll(response.data, response.meta))
}

function* download({ payload }: Action) {
    const { applicationId, creditProposal, withRealExpirationDate } = payload

    const formatedCustomerName = creditProposal.customer_name?.replace(/ /g, "")
    const formatedDate = formatDate(new Date, { format: 'DDMMYYYY' })

    yield call(
        applicationWorkflowService.download,
        `${applicationId}/credit_proposals/${creditProposal.id}.pdf?soft=${!withRealExpirationDate}`,
        `$Propuesta-${formatedCustomerName}-${formatedDate}.pdf`
    )

    yield put(creditProposalsActions.set('requestStatus', undefined))
}

function* getOne({ payload }: Action) {
    const response: Response = yield call(
        applicationWorkflowService.findOnePath,
        payload.applicationId,
        `credit_proposals/${payload.creditProposalId}`
    )
    yield put(creditProposalsActions.setOne(response.data))
}

function* sendEmail({ payload }: Action) {
    yield call(creditProposalService.createPath, `${payload.creditProposalId}/send_email`, {})
    yield put(snackbarActions.add('success', 'Se ha enviado el correo'))
    yield put(creditProposalsActions.set('requestStatus', undefined))
}

function* downloadAmortizationTable({ payload }: Action) {
    const { applicationId, creditProposal, options } = payload
    let productApplicationId = creditProposal.product_application_id

    if (!productApplicationId) {
        // If the credit proposal doesn't have a product application, we need to get it
        const response: Response = yield call(
            applicationWorkflowService.findOnePath,
            applicationId,
            `credit_proposals/${creditProposal.id}`
        )
        const newCreditProposal: CreditProposalType = response.data

        if (newCreditProposal.product_application_id) {
            productApplicationId = newCreditProposal.product_application_id
            yield put(creditProposalsActions.setOne(newCreditProposal))
        } else {
            yield put(snackbarActions.add('warning', 'No se ha terminado de generar la tabla de amortización'))
        }
    }

    if (productApplicationId) {
        const service = options?.format === 'excel'
            ? abacusProductApplicationService
            : productApplicationService

        const downloadPath = options?.format === 'excel'
            ? '/render_excel'
            : '.pdf'

        const extension = options?.format === 'excel'
            ? '.xlsx'
            : '.pdf'

        yield call(
            service.download,
            `${productApplicationId}${downloadPath}`,
            `${creditProposal.customer_name}-${creditProposal.workflow_title}-tabla-amortización${extension}`,
            {},
            { disableMimeType: true },
            { with_taxes: options?.withTaxes }
        )
    }

    yield put(creditProposalsActions.set('requestStatus', undefined))
}

function* updateStatus({ payload }: Action) {
    const { applicationId, creditProposalId, from } = payload
    yield call(
        applicationWorkflowService.patchPath,
        `${applicationId}/credit_proposals/${creditProposalId}/update_status`,
        { status: payload.status }
    )

    if (from === 'list') {
        yield put(creditProposalsActions.getAll(applicationId, {}))
    } else if (from === 'details') {
        yield put(creditProposalsActions.getOne(applicationId, creditProposalId))
    } else {
        yield put(creditProposalsActions.set('isLoading', false))
    }

    yield put(snackbarActions.add('success', 'Se ha actualizado el estatus de la propuesta'))
}

export function* creditProposalsSagas() {
    yield takeLatest(CREDIT_PROPOSAL_TYPES.GET_ONE, sagaWrapper(getOne, creditProposalsActions.setOne()))
    yield takeLatest(CREDIT_PROPOSAL_TYPES.GET_ALL, sagaWrapper(getAll, creditProposalsActions.setAll()))
    yield takeLatest(CREDIT_PROPOSAL_TYPES.UPDATE_STATUS, sagaWrapper(updateStatus, creditProposalsActions.set('isLoading', false)))
    yield takeLatest(CREDIT_PROPOSAL_TYPES.SEND_EMAIL, sagaWrapper(sendEmail, creditProposalsActions.set('isDownloading', false)))
    yield takeLatest(CREDIT_PROPOSAL_TYPES.DOWNLOAD, sagaWrapper(download, creditProposalsActions.set('isDownloading', false)))
    yield takeLatest(CREDIT_PROPOSAL_TYPES.DOWNLOAD_AMORTIZATION_TABLE, sagaWrapper(downloadAmortizationTable, creditProposalsActions.set('isDownloading', false)))
}
import { Fragment, useEffect, useState } from "react"
import { Navigate } from "react-router-dom"
import { Grid, Typography } from "@mui/material"
import { FetchSvg, Loading } from "src/components"
import { Workflow } from "src/types"
import { useIsMobile, segment, useIsKredi } from "src/helpers"
import { useStyles } from "./styles"
import _ from "lodash"

import {
    ArrowForward as ArrowIcon,
    AssignmentOutlined as AssignmentIcon,
} from "@mui/icons-material"


import { useSelector, useDispatch } from "react-redux"
import { applicationActions } from "src/store/application/actions"
import { images } from "src/helpers/images"

export function StartApplication() {
    const { classes, cx } = useStyles()
    const dispatch = useDispatch()

    const organization = useSelector(state => state.organization)
    const isLoading = useSelector(state => state.applications.isLoading)
    const isSegmentLoaded = useSelector(state => state.auth.isSegmentLoaded)
    const isBancoppel = organization.slug === 'bancoppel'
    const Image = images[organization.slug!]?.startApplication || Fragment

    const publishedWorkflows = _.orderBy(
        _.filter(organization.workflows, workflow => workflow.status === 'published'),
        'sort_order'
    ) || []

    useEffect(() => {
        if (isSegmentLoaded) {
            segment.page('Home')
        }
    }, [window.analytics])

    const createApplication = (workflowId: string, workFlowTitle: string) => {
        dispatch(applicationActions.createApplication(workflowId, workFlowTitle))
    }

    if (publishedWorkflows.length == 0) {
        return <Navigate to="/maintenance" />
    }

    return (
        <Loading isLoading={isLoading} className={classes.loadingContainer}>
            <Grid container className={classes.container}>
                <div className={classes.backgroundGray} />

                <Grid container style={{ maxHeight: '100%' }}>
                    <Grid item xs={5} className={cx(classes.heroContainer, { [classes.bancoppelHeroContainer]: isBancoppel })}>
                        <Image />
                    </Grid>

                    <Grid
                        container
                        item xs={12} md={7}
                        alignItems={((organization.workflows?.length || 0) > 2) ? "flex-start" : "center"}
                        justifyContent="center"
                        className={classes.dataContainer}
                    >
                        <Grid container>
                            <Grid item xs={12} className={classes.headerContainer} style={((organization.workflows?.length || 0) > 2) ? { paddingTop: 77 } : { marginTop: -77 }}>
                                <Typography variant="h1">¡Inicia tu solicitud para comprar tu casa!</Typography>
                            </Grid>

                            <Grid container className={classes.optionsContainer}>
                                <Grid item xs={12}>
                                    {publishedWorkflows.map((workflow: Workflow, index: number) => (
                                        <Grid container key={workflow.id}>
                                            <Grid
                                                item
                                                xs={12}
                                                className={cx({
                                                    [classes.credit]: !isBancoppel,
                                                    [classes.bancoppelCredit]: isBancoppel,
                                                })}
                                                onClick={() => createApplication(workflow.id!, workflow.title!)}
                                            >
                                                <div>
                                                    {!isBancoppel && (
                                                        <FetchSvg
                                                            url={workflow?.icon || ''}
                                                            className={classes.workflowIcon}
                                                            defaultIcon={<AssignmentIcon className={classes.workflowIcon} />}
                                                        />
                                                    )}

                                                    <Typography> {workflow.label || workflow.title} </Typography>
                                                </div>

                                                <ArrowIcon
                                                    className={cx(classes.arrowIcon, {
                                                        [classes.bancoppelArrowIcon]: isBancoppel
                                                    })}
                                                />
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Loading>
    )
}
import { alpha } from "@mui/material"
import { tss } from "tss-react/mui"
import { SMALL_TABLET_BREAKPOINT } from "src/assets/theme/theme"

export const useStyles = tss.create(({ theme }) => ({
    root: {
        alignItems: 'center',
        borderRadius: 10,
        border: `2px solid ${theme.palette.primary.main}`,
        display: 'flex',
        height: 60,
        padding: 16,
        minHeight: 60,
        '&.contained': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
        '&.outlined': {
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
            color: theme.palette.primary.main,
        },
        [theme.breakpoints.down('md')]: {
            height: 49,
        },
        [theme.breakpoints.down(SMALL_TABLET_BREAKPOINT)]: {
            height: 'auto',
            flexDirection: 'column',
            gap: 24
        },
        [theme.breakpoints.down('sm')]: {
            gap: 16,
        },
    },
    children: {
        color: 'currentcolor',
        fontSize: 16,
        fontWeight: 'bold',
        lineHeight: '22px',
        letterSpacing: '0.15px',
        width: '100%',
        [theme.breakpoints.down(SMALL_TABLET_BREAKPOINT)]: {
            textAlign: 'center',
            fontSize: 20,
            lineHeight: '26px',
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 14,
            lineHeight: '22px',
        }
    },
    actionButton: {
        flex: '0 0 auto',
        minWidth: 203,
        minHeight: 36,
        '&.contained': {
            backgroundColor: theme.palette.primary.contrastText,
            color: theme.palette.primary.main,
            '&:hover': {
                backgroundColor: `${theme.palette.primary.contrastText} !important`,
            }
        },
        '&.outlined': {
            backgroundColor: `${theme.palette.primary.contrastText} !important`,
            color: theme.palette.primary.main,
            '&:hover': {
                boxShadow: 'none !important',
            }
        },
        [theme.breakpoints.down('md')]: {
            height: 25,
        },
        [theme.breakpoints.down(SMALL_TABLET_BREAKPOINT)]: {
            height: 32,
            width: 408,
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        }
    }
}))
import { Fragment } from 'react'
import { Box, Typography } from '@mui/material'
import { EmptyState } from 'src/components'
import { images } from 'src/helpers/images'
import { useStyles } from './styles'

import { DocumentListViewProps } from '..'
import { ApprovalActions } from '../../Actions'
import { ApprovalStatus } from '../../Status'

import { useSelector } from 'react-redux'

export function MobileDocumentList({ approvals = [], setDocDialog }: DocumentListViewProps) {
    const { classes } = useStyles()

    const orgSlug = useSelector(state => state.organization?.slug)

    if (approvals.length == 0) {
        return (
            <EmptyState
                text='No tienes documentos pendientes'
                className={classes.emptyState}
                customIcon={orgSlug ? images[orgSlug].docs.empty : undefined}
            />
        )
    }

    return (
        <Box className={classes.root}>
            <Box className={classes.table}>
                <Box className={classes.tableHead}>
                    <Typography className={classes.cell}>
                        Tipo de documento
                    </Typography>

                    <Typography className={classes.cell}>
                        Estatus
                    </Typography>

                    {/* <Typography className={classes.cell}>
                        Fecha
                    </Typography> */}
                </Box>

                {approvals.map(approval => {
                    return (
                        <Fragment key={approval?.id}>
                            <Typography className={classes.cell}>
                                {approval.field?.label || 'Sin nombre'}
                            </Typography>

                            <Box className={classes.cell}>
                                <ApprovalStatus approval={approval} />
                            </Box>

                            {/* <Typography className={classes.cell}>
                                {formatDate(field?.created_at) || 'No disponible'}
                            </Typography> */}

                            <Box sx={{ flex: '1 100%', marginBottom: '32px' }}>
                                <ApprovalActions
                                    approval={approval}
                                    setDocDialog={setDocDialog}
                                    buttonProps={{
                                        fullWidth: true,
                                        className: classes.actionButton
                                    }}
                                />
                            </Box>
                        </Fragment>
                    )
                })}
            </Box>
        </Box>
    )
}
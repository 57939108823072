import { tss } from "tss-react/mui";
import { APPLICATION_DRAWER_FLOW_SIDEBAR_WIDTH } from "./Sidebar/Sidebar.styles";

export const useStyles = tss.create(({ theme }) => ({
	stageRoot: {
		[theme.breakpoints.down('sm')]: {
			height: '100%'
		}
	},
	container: {
		height: '100%',
		display: 'flex',
	},
	content: {
		display: 'flex',
		alignItems: 'flex-start',
		justifyContent: 'center',
		width: `calc(100% - ${APPLICATION_DRAWER_FLOW_SIDEBAR_WIDTH}px)`,
		height: '100%',
		maxHeight: '100%',
		overflowY: 'auto',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'column',
			width: '100%',
		}
	},
	contentWBanner: {
		flexDirection: 'column',
		[theme.breakpoints.down('sm')]: {
			marginTop: 65,
		},

	},
	loadingContainer: {
		height: 'calc(100% - 60px)',
		[theme.breakpoints.down('sm')]: {
			height: 'calc(100vh - 50px)',
		}
	},
	breadcrumbs: {
		padding: '60px 16px 0'
	},
	stageContainer: {
		maxWidth: 1440,
		padding: '54px 108px 60px 56px',
		[theme.breakpoints.down('sm')]: {
			padding: '40px 16px 8px',
			marginTop: 40,
		},
		[theme.breakpoints.between('sm', 'md')]: {
			padding: '40px 112px 24px',
			marginTop: 40,
		},
	},
	stageContainerWBanner: {
		[theme.breakpoints.down('md')]: {
			marginTop: 0,
		},
	},
	buttonContainer: {
		[theme.breakpoints.down('md')]: {
			width: '100%',
			height: 42
		}
	},
	cancelButton: {
		[theme.breakpoints.down('md')]: {
			order: 2,
			border: 'none',
			width: '100%',
			'& svg': {
				display: 'none'
			}
		}
	},
	submitButton: {
		padding: '16px 20px',
		width: 171,
		color: "white",
		[theme.breakpoints.down('md')]: {
			order: 1,
			marginBottom: 12,
			width: '100%',
			height: 42,
		}
	},
	buttonWithResume: {
		[theme.breakpoints.down('md')]: {
			maxWidth: '50%',
			order: 2,
			padding: 12,
		}
	},
	title: {
		color: theme.palette.primary.dark,
		padding: 0,
		[theme.breakpoints.down('sm')]: {
			marginBottom: '40px',
			display: 'none'
		}
	},
	bannerCreateAccount: {
		background: theme.palette.primary.main,
		display: 'flex',
		alignItems: 'center',
		gap: 6,
		padding: '8px 16px',
		"& > svg": {
			fill: "#FFF"
		},
		"&:hover": {
			cursor: 'pointer'
		},
	},
	bannerCreateAccountText: {
		color: "#FFFFFF",
		[theme.breakpoints.down('sm')]: {
			fontSize: 12,
		}
	},
	fieldsContainer: {
		minHeight: 'calc(100vh - 345px)',
		display: 'flex',
		flexDirection: 'column',
		justifyContent: 'space-between',
		[theme.breakpoints.down('sm')]: {
			minHeight: 'calc(100vh - 226px)',
		},
	},
	fieldsContainerFirstSection: {
		[theme.breakpoints.down('sm')]: {
			minHeight: 'calc(100vh - 185px) !important',
		}
	},
	titleContainer: {
		minHeight: 70,
		[theme.breakpoints.down('sm')]: {
			minHeight: 0,
		}
	},
	titleWithoutDescription: {
		paddingBottom: 40,
		[theme.breakpoints.down('sm')]: {
			paddingBottom: 0,
		}
	},
	formBuilderActions: {
		[theme.breakpoints.down('md')]: {
			width: '100%',
			backgroundColor: '#fff',
			display: 'flex',
			alignItems: 'flex-start'
		},
	},
	itemContainer: {
		'& .mde-preview-content': {
			'& p': {
				margin: 0
			}
		}
	}
}));
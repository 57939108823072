import { Button } from '@mui/material'
import { Dialog, DialogContent, Grid, Typography } from '@mui/material'
import { useIsMobile } from 'src/helpers'
import { useStyles } from './ConfirmModal.styles'

export interface ConfirmModalProps {
    open: boolean,
    onCancel: Function | any,
    onConfirm: Function | any,
    title: string,
    description: string,
    cancelText?: string,
    confirmText?: string,
}

/** Show a modal/dialog with two actions (confirm and cancel) when open param is true */
export const ConfirmModal = (props: ConfirmModalProps) => {
    const { classes, cx } = useStyles()
    const { open, onCancel, onConfirm, title, description, cancelText, confirmText } = props
    const isMobile = useIsMobile()
    return (
        <Dialog
            open={open}
            onClose={onCancel}
            PaperProps={{
                classes: {
                    root: classes.paper
                }
            }}
        >
            <DialogContent className={classes.content}>
                <Grid container direction="column">
                    <Grid item>
                        <Typography className={classes.title} align="center"> {title} </Typography>
                    </Grid>
                    <Grid item>
                        <Typography className={classes.description} align="center"> {description} </Typography>
                    </Grid>
                    <Grid item>
                        <Grid container direction="row" justifyContent={isMobile ? 'space-between' : "space-around"} spacing={1}>
                            <Grid item>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    data-testid="confirmModal-cancel-button"
                                    onClick={onCancel}
                                    className={classes.button}
                                    size={isMobile ? 'small' : 'medium'}
                                >
                                    {cancelText || 'Volver'}
                                </Button>
                            </Grid>

                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size={isMobile ? 'small' : 'medium'}
                                    onClick={onConfirm}
                                    cy-data="confirmation-button"
                                    data-testid="confirmModal-confirm-button"
                                    className={classes.button}
                                >
                                    {confirmText || 'Eliminar'}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>

        </Dialog>
    )
}
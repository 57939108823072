import { ShortcutProps } from "src/components"
import { getApplicationParentId } from "src/helpers"
import { images } from "src/helpers/images"
import { Application, CreditProposalType, PaymentType, RelatedApplication } from "src/types"

type ShorcutItemType = ShortcutProps & { hidden?: boolean }

interface GetShortcutsParams {
    organizationSlug: string
    isKredi?: boolean

    currentCustomerId?: string
    application?: Application
    isPriority?: boolean
    canContinue?: boolean
    currentSectionLink?: string

    relatedApplications: RelatedApplication[]
    creditProposals: CreditProposalType[]

    requiredDocumentsCount: number
    payments?: PaymentType[]

    handleCoaccreditOpen: () => void
    setCancelApplicationOpen: (open: boolean) => void
}

export const getShortcuts = (props: GetShortcutsParams): ShorcutItemType[] => {
    const {
        application,
        organizationSlug,
        canContinue,
        currentSectionLink,
        isPriority,
        relatedApplications,
        creditProposals,
        isKredi,
        requiredDocumentsCount,
        setCancelApplicationOpen,
        handleCoaccreditOpen,
        currentCustomerId,
        payments
    } = props

    const isRejected = application?.status == 'rejected'
    const isCancelled = application?.status == 'cancelled'
    const isApplicationOwner = currentCustomerId === application?.customer?.id
    const hasApplicationParent = Boolean(getApplicationParentId(application))
    const hasCreditProposals = creditProposals.length > 0
    const hasAcceptedCreditProposal = creditProposals.some(({ status }) => status == 'accepted')

    const redirectToApplication = isRedirectToApplication(
        application?.id,
        isPriority,
        canContinue,
        currentSectionLink
    )

    const result: ShorcutItemType[] = [
        {
            title: 'Documentos',
            startIcon: images[organizationSlug].home.scDocuments,
            to: redirectToApplication ? currentSectionLink : `/home/applications/${application?.id}/documents`,
            hidden: isRejected || isCancelled,
            badge: requiredDocumentsCount > 0 ? ({
                badgeContent: requiredDocumentsCount,
                color: 'error',
            }) : undefined
        },
        {
            title: 'Ingresos',
            startIcon: images[organizationSlug].home.scIncome,
            to: redirectToApplication ? currentSectionLink : `/home/applications/${application?.id}/employments`,
            hidden: isPriority || !isApplicationOwner || isRejected,
        },
        {
            title: 'Agregar coacreditado',
            startIcon: images[organizationSlug].home.scCoaccedited,
            hidden: hasApplicationParent || !isApplicationOwner || isRejected || isCancelled,
            onClick: handleCoaccreditOpen,
        },
        {
            title: 'Propuesta de crédito',
            startIcon: images[organizationSlug].home.scCreditProposal,
            to: `/home/applications/${application?.id}/proposals`,
            hidden: hasApplicationParent || !isApplicationOwner || !hasCreditProposals || isRejected,
        },
        {
            title: 'Pagos',
            startIcon: images[organizationSlug].home.scPayments,
            to: `/home/applications/${application?.id}/payments`,
            // to: payments?.length == 1
            //     ? `/home/applications/${application?.id}/payments/${payments[0].id}?only_one=true`
            //     : `/home/applications/${application?.id}/payments`,
            hidden: !hasAcceptedCreditProposal || payments?.length == 0 || isRejected,
        },
        {
            title: 'Cancelar solicitud',
            startIcon: images[organizationSlug].home.scDocuments,
            onClick: () => setCancelApplicationOpen(true),
            hidden: !isKredi || Boolean(application?.credit_id) || isRejected || isCancelled,
        },
        {
            title: 'Nueva solicitud',
            startIcon: images[organizationSlug].home.scDocuments,
            to: '/application',
            hidden: ['in_process', 'approved', 'draft', 'action_required'].includes(application?.status || '') || hasApplicationParent,
        },
    ]

    return result.filter(s => !s.hidden)
}

function isRedirectToApplication(
    applicationId?: string,
    isPriority?: boolean,
    canContinue?: boolean,
    currentSectionLink?: string
) {
    if (!applicationId) return false

    // If the current stage is a priority and the application can continue,
    // the user should be redirected to the current section link to continue the application
    return isPriority && canContinue && currentSectionLink
}
import { tss } from "tss-react/mui"
import { SMALL_TABLET_BREAKPOINT } from "src/assets/theme/theme"

export const useStyles = tss.create(({ theme }) => ({
    root: {
        marginTop: 48,
        minHeight: 188
    },
    container: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        }
    },
    title: {
        fontSize: 34,
        fontWeight: 'bold',
        lineHeight: '40px',
        color: theme.palette.primary.main,
        paddingBottom: 16,
        [theme.breakpoints.down(SMALL_TABLET_BREAKPOINT)]: {
            fontSize: 24,
            lineHeight: '30px',
            letterSpacing: '0.18px'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 20,
            lineHeight: '26px',
            letterSpacing: '0.15px',
            paddingBottom: 0
        }
    },
    stage: {
        fontSize: 14,
        color: theme.palette.primary.main,
        fontWeight: 500,
        '& path': {
            fill: theme.palette.text.primary
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
            color: theme.palette.text.primary,
        }
    },
    status: {
        fontSize: 12,
        '& svg': {
            fontSize: '12px !important'
        },
        [theme.breakpoints.down('sm')]: {
            fontSize: 12,
            textAlign: 'right'
        }
    },
    textIcon: {
        display: 'flex',
        alignItems: 'center',
        gap: 6,
        '& svg': {
            fontSize: 14
        }
    },
    stageStatusContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
        width: '40%',
        [theme.breakpoints.down('sm')]: {
            paddingTop: 12,
            width: '100%',
            flexDirection: 'row',
            justifyContent: 'space-between',
            '& > :last-child': {
                display: 'flex',
                justifyContent: 'flex-end'
            },
        }
    }
}))